import { isMobile } from "react-device-detect";
import { TextVariant } from "../components/typography";

// colors based on mock-ups as of 8/22/21
// color descriptions based on results of: https://www.colorhexa.com
export const COLORS = {
  alabaster: "#FCFCFC", // white
  oldLace: "#FCF4E7", // grayish organge
  gamboge: "#E2950C", // vivid orange
  shipGray: "#4F4F4F", // dark gray
  saddleBrown: "#87420D", // brown orange
  fountainBlue: "#5BBECD", 
  silverSand: "#C4C4C4",
  moderateCyan: '#5ABECD',
  minsk: "#342984", // moderate blue
}

// conts based on mock-ups as of 8/22/21
interface Fonts {
  header;
  heading;
  heading_body;
  section;
  section_body;
  sub_section;
  sub_section_body;
  footer;
  link;
}

export function getFonts(): Fonts {
  console.log("ISMOBILE: " + isMobile)
  // return FONTS;
  return isMobile
    ? MOBILE_FONTS
    : FONTS;
}

const FONTS = {
  header: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15em',
  },
  heading: {
    fontFamily: 'Lato',
    fontSize: '72px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '86px',
    letterSpacing: '0em',
  },
  heading_body: {
    fontFamily: 'Lato',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '50px',
    letterSpacing: '0em',
  },
  section: {
    fontFamily: 'Lato',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '43px',
    letterSpacing: '0em',
  },
  section_body: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  sub_section: {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  sub_section_body: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  footer: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15em',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0em',
  },
}

const MOBILE_FONTS = {
  header: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15em',
  },
  heading: {
    fontFamily: 'Lato',
    fontSize: '56px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '67px',
    letterSpacing: '0em',
  },
  heading_body: {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '38px',
    letterSpacing: '0em',
  },
  section: {
    fontFamily: 'Lato',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '34px',
    letterSpacing: '0em',
  },
  section_body: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  sub_section: {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  sub_section_body: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  footer: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15em',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0em',
  },
}

export interface ITheme {
  colors: {
    alabaster?: string;
    oldLace?: string;
    gamboge?: string;
    shipGray?: string;
    saddleBrown?: string;
    fountainBlue?: string;
    silverSand?: string;
    minsk?: string;
  };
  semanticColors: {
    [key: string]: string;
  };
  fonts: {
    [key in TextVariant]: {
      fontFamily: string;
      fontSize: string;
      fontStyle: string;
      fontWeight: number;
      lineHeight: string;
      letterSpacing?: string;
    };
  };
}
// 540, 740, 960, 1180, 1540
export const breakpoints = {
  xSmall: 540,
  small: 740,
  medium: 960,
  mediumLarge: 1180,
  large: 1540,
  xLarge: 1920,
};

export const imageSizes = {
  small: 300,
  small_mobile: 320,
  large: 775,
  large_mobile: 320,
};
;

export const theme: ITheme = {
  colors: {
    ...COLORS,
  },
  semanticColors: {},
  fonts: {
    small_C: FONTS.sub_section_body,
    small_D: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
    },
    small_DD: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
    },
    medium_D: FONTS.sub_section,
    large_C: FONTS.section,
    large_D: FONTS.heading_body,
    xLarge_C: FONTS.heading,
  },
};
