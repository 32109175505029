/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import { COLORS, getFonts } from "../style/theme";
import { isMobile } from "react-device-detect";

function Header() {
  const fonts = getFonts();
  const headerStyle = css({
    height: isMobile ? 75 : 100,
    backgroundColor: COLORS.alabaster,
  });
  const colStyle = css({ display: "flex", justifyContent: "flex-end" });
  const linkAlign = isMobile ? {
    margin: 20,
    paddingTop: 5,
  } : {
    margin: 25,
    paddingTop: 10,
  };
  const linkStyles = css({
    ...linkAlign,
    ...fonts.header,
    color: COLORS.saddleBrown,
  });
  const activeLinkStyle = {
    fontWeight: 700,
    borderBottom: `1px solid ${COLORS.saddleBrown}`,
  };

  return (
      <header css={headerStyle}>
        <Container>
          <Row>
            <Col css={colStyle}>
              <Link to="/beta/" css={linkStyles} activeStyle={activeLinkStyle}>
                BETA
              </Link>
              <Link to="/about/" css={linkStyles} activeStyle={activeLinkStyle}>
                ABOUT
              </Link>
            </Col>
          </Row>
        </Container>
      </header>
  );
}

export default Header;
