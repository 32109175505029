/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "./ThemeProvider";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import { Text, TextVariant } from "./typography";
import { ITheme, breakpoints, getFonts, COLORS } from "../style/theme";
import { BrowserView, isMobile, MobileView } from "react-device-detect";


  const MAIL_TO_ADDRESS = "mailto:rich@careteamvitals.onmicrosoft.com";

function getStyles() {
  const fonts = getFonts();
  const desktop = {
    footer: {
      backgroundColor: COLORS.oldLace,
      padding: "12.5px 0px",
    },
    contactlinkText: {
      ...fonts.footer,
      textTransform: "uppercase",
      color: COLORS.saddleBrown,
    },
    linkDiv: {

    },
    link: {
      ...fonts.footer,
      textTransform: "uppercase",
      color: COLORS.saddleBrown,
    },
    activeLink: {
      fontWeight: 700,
      borderBottom: `1px solid ${COLORS.saddleBrown}`,
    }
  };
  const mobile = {
    ...desktop,
    linkDiv: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
  return isMobile ? mobile : desktop;
}

function Footer() {
  const styles = getStyles();

  const Contact: React.FC = () => {
    return (
      <a href={MAIL_TO_ADDRESS}>
        <p css={styles.contactlinkText}>
          Contact
        </p>
      </a>
    );
  }

  const Terms: React.FC = () => {
    return (
      <div css={styles.linkDiv}>
        <Link
          to="/terms-of-service/"
          css={styles.link}
          activeStyle={styles.activeLink}
        >
          Policy
        </Link>
      </div>
    );
  }
  return (
    <footer css={styles.footer}>
      <Container fluid>
        <BrowserView>
          <Row justify="end">
            <Col sm={2}>
              <Contact />
            </Col>
            <Col sm={2}>
              <Terms />
            </Col>
          </Row>
        </BrowserView>
        <MobileView>
          <Row justify="around">
            <Col>
              <Contact />
            </Col>
            <Col>
              <Terms />
            </Col>
          </Row>
        </MobileView>
      </Container>
    </footer>
  );
}

export default Footer;
